import Lenis from 'lenis';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { ANIMATION_CONFIG } from '../config/constants';

export class SmoothScroll {
  constructor() {
    this.lenis = null;
    this.init();
  }

  init() {
    this.lenis = new Lenis({
      duration: ANIMATION_CONFIG.SCROLL.DURATION,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      orientation: 'vertical',
      smoothWheel: true,
      wheelMultiplier: ANIMATION_CONFIG.SCROLL.WHEEL_MULTIPLIER,
      touchMultiplier: 2
    });

    this.lenis.on('scroll', ScrollTrigger.update);
    gsap.ticker.add((time) => {
      this.lenis.raf(time * 1000);
    });

    this.lenis.stop();
    this.lenis.scrollTo(0, { immediate: true });
    this.lenis.start();

    ScrollTrigger.addEventListener('refresh', () => {
      this.lenis.resize();
      this.lenis.scrollTo(0, { immediate: true });
    });
  }

  reset() {
    if (this.lenis) {
      this.lenis.stop();
      this.lenis.scrollTo(0, {
        immediate: true,
        force: true
      });
      this.lenis.start();
    }
  }

  destroy() {
    if (this.lenis) {
      this.lenis.destroy();
      this.lenis = null;
    }
  }
}
