import 'focus-visible';
import { inject } from '@vercel/analytics';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import VanillaTilt from 'vanilla-tilt';

import { DateFormatter } from './utils/dateFormatter';
import { CoordinatesGenerator } from './utils/coordinatesGenerator';
import { SmoothScroll } from './modules/scroll';
import { Menu } from './modules/menu';
import { PageTransition } from './modules/pageTransition';

gsap.registerPlugin(ScrollTrigger);

class UIManager {
  static updateElements() {
    UIManager.updateDateDisplay();
    UIManager.updateCoordinatesDisplay();
    UIManager.updateYearDisplay();
  }

  static updateDateDisplay() {
    const element = document.getElementById('date-display');
    if (element) {
      element.textContent = DateFormatter.formatDate();
    }
  }

  static updateCoordinatesDisplay() {
    const element = document.getElementById('coordinates-display');
    if (element) {
      const coords = CoordinatesGenerator.getRandomCoordinates();
      element.innerHTML = CoordinatesGenerator.formatCoordinates(
        coords.latitude,
        coords.longitude
      );
    }
  }

  static updateYearDisplay() {
    const element = document.getElementById('currentYear');
    if (element) {
      element.textContent = DateFormatter.getCurrentYear();
    }
  }
}

class App {
  constructor() {
    inject();
    this.smoothScroll = new SmoothScroll();
    this.menu = new Menu();
    this.scrollTriggers = [];
    PageTransition.setMenuInstance(this.menu);
    PageTransition.init();
    this.initPage();
    this.initParallaxEffects();

    if (window.barba) {
      window.barba.hooks.afterEnter(({ next }) => {
        this.destroyPage();
        this._waitForDOMUpdate(next.container).then(() => {
          this.initPage();
          this.smoothScroll.reset();
        });
      });
    }
    window.addEventListener('resize', () => {
      this.smoothScroll.reset();
      ScrollTrigger.refresh();
    });
  }

  _waitForDOMUpdate(container) {
    return new Promise((resolve) => {
      const observer = new MutationObserver(() => {
        observer.disconnect();
        requestAnimationFrame(resolve);
      });
      observer.observe(container, { childList: true, subtree: true });
    });
  }

  destroyPage() {
    this.scrollTriggers.forEach((trigger) => trigger.kill());
    this.scrollTriggers = [];

    window.scrollTo(0, 0);
    this.smoothScroll.reset();

    ScrollTrigger.clearMatchMedia();
    ScrollTrigger.refresh(true);
  }

  initPage() {
    UIManager.updateElements();
    this.initScrollTrigger();
    this.initParallaxEffects();
    this.initTiltIfDesktop();

    requestAnimationFrame(() => {
      ScrollTrigger.refresh(true);
      this.smoothScroll.reset();
    });
  }

  initScrollTrigger() {
    const headerTrigger = ScrollTrigger.create({
      start: 'top 0',
      end: 99999,
      scrub: true,
      toggleActions: 'play none none reverse',
      toggleClass: {
        className: 'header__inner--scrolled',
        targets: '.header__inner'
      }
    });
    this.scrollTriggers.push(headerTrigger);
  }

  initParallaxEffects() {
    gsap.utils.toArray('.with-parallax').forEach((section) => {
      const image = section.querySelector('img');
      if (image) {
        const trigger = ScrollTrigger.create({
          trigger: section,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          animation: gsap.to(image, {
            yPercent: 25,
            ease: 'none'
          })
        });
        this.scrollTriggers.push(trigger);
      }
    });
  }
  initTiltIfDesktop() {
    const cardTilt = document.querySelectorAll('.card-tilt');
    VanillaTilt.init(cardTilt, {
      max: 10,
      speed: 3000,
      transition: true,
      easing: 'cubic-bezier(.03,.98,.52,.99)',
      perspective: 1500,
      gyroscope: true,
      glare: true,
      'max-glare': 0.35
    });
  }
}

new App();
