export const ANIMATION_CONFIG = {
  MENU: {
    DURATION: 0.5,
    STAGGER: 0.1,
    EASE: 'expo.inOut'
  },
  TRANSITION: {
    DURATION: 0.5,
    OPACITY: 0
  },
  SCROLL: {
    DURATION: 2.2,
    WHEEL_MULTIPLIER: 1.2
  }
};
