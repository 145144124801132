import gsap from 'gsap';

import { ANIMATION_CONFIG } from '../config/constants';

export class Menu {
  constructor() {
    this.menu = document.querySelector('.menu');
    this.overlayBg = document.querySelector('.overlay-menu');
    this.header = document.querySelector('.header');
    this.bentoMenu = document.getElementById('bentoMenu');
    this.menuItems = document.querySelectorAll('.main-menu--items li');
    this.menuLogo = document.querySelectorAll('.header__logo');
    this.init();
  }

  init() {
    if (this.menu) {
      this.menu.addEventListener('click', () => this.toggleMenu());
    }
    if (this.overlayBg) {
      this.overlayBg.addEventListener('click', (e) =>
        this.handleOverlayClick(e)
      );
    }
    if (this.bentoMenu) {
      this.bentoMenu.addEventListener('click', () => this.toggleBentoMenu());
    }
    if (this.menuItems) {
      this.menuItems.forEach((item) => {
        item.addEventListener('click', () => this.handleMenuItemClick());
      });
    }
    if (this.menuLogo) {
      this.menuLogo.forEach((item) => {
        item.addEventListener('click', () => this.handleMenuLogoClick());
      });
    }
  }

  handleOverlayClick(e) {
    if (e.target === this.overlayBg) {
      this.closeMenu();
    }
  }

  handleMenuItemClick() {
    this.closeMenu();
    this.closeBentoMenu();
  }

  handleMenuLogoClick() {
    this.closeMenu();
    this.closeBentoMenu();
  }

  toggleMenu() {
    this.header.classList.contains('active-menu')
      ? this.closeMenu()
      : this.openMenu();
  }

  openMenu() {
    if (this.header.classList.contains('active-menu')) return;
    this.header.classList.add('active-menu');
    const tl = gsap.timeline();
    gsap.set('.main-menu--items li', { x: -167, autoAlpha: 0 });
    gsap.set('.main-social--items li', { x: -167, autoAlpha: 0 });
    tl.to('.overlay-menu', {
      duration: 0.3,
      opacity: 1,
      visibility: 'visible',
      zIndex: 15
    })
      .to(
        '.main-menu',
        {
          duration: 0.7,
          y: 0,
          ease: ANIMATION_CONFIG.MENU.EASE
        },
        0
      )
      .to(
        '.main-menu--items li',
        {
          x: 0,
          stagger: { amount: 0.5 },
          ease: 'power2.out',
          autoAlpha: 1
        },
        0.5
      )
      .to(
        '.main-social--items li',
        {
          x: 0,
          stagger: { amount: 0.5 },
          ease: 'power2.out',
          autoAlpha: 1
        },
        0.5
      )
      .to('.main-menu--socials div', {
        opacity: 1,
        stagger: ANIMATION_CONFIG.MENU.STAGGER,
        ease: 'power3.inOut'
      });
  }

  closeMenu() {
    if (!this.header.classList.contains('active-menu')) return;
    this.header.classList.remove('active-menu');
    const tl = gsap.timeline();
    tl.to('.main-menu', {
      duration: 0.7,
      y: -582,
      ease: ANIMATION_CONFIG.MENU.EASE
    }).to(
      '.overlay-menu',
      {
        autoAlpha: 0,
        zIndex: -10
      },
      0.4
    );
    return tl;
  }

  toggleBentoMenu() {
    const isActive = this.bentoMenu.classList.contains('active-bento');
    const animation = isActive
      ? this.closeBentoAnimation()
      : this.openBentoAnimation();
    animation.play();
  }

  openBentoAnimation() {
    const tl = gsap.timeline();
    const centerConfig = {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      duration: ANIMATION_CONFIG.MENU.DURATION
    };
    tl.to(this.bentoMenu, {
      rotation: 45,
      duration: ANIMATION_CONFIG.MENU.DURATION,
      onComplete: () => {
        this.bentoMenu.classList.add('active-bento');
      }
    })
      .to('span:nth-child(1)', centerConfig, 0)
      .to('span:nth-child(3)', centerConfig, 0)
      .to('span:nth-child(7)', centerConfig, 0)
      .to('span:nth-child(9)', centerConfig, 0);
    return tl;
  }

  closeBentoAnimation() {
    const tl = gsap.timeline();
    const positions = {
      1: { top: '0', left: '0', transform: 'translate(0, 0)' },
      3: { top: '0', left: '100%', transform: 'translate(-100%, 0)' },
      7: { top: '100%', left: '0', transform: 'translate(0, -100%)' },
      9: { top: '100%', left: '100%', transform: 'translate(-100%, -100%)' }
    };
    tl.to(this.bentoMenu, {
      rotation: 0,
      duration: ANIMATION_CONFIG.MENU.DURATION
    });
    Object.entries(positions).forEach(([child, position]) => {
      tl.to(
        `span:nth-child(${child})`,
        {
          ...position,
          duration: ANIMATION_CONFIG.MENU.DURATION
        },
        0
      );
    });
    tl.eventCallback('onComplete', () => {
      this.bentoMenu.classList.remove('active-bento');
      [1, 3, 7, 9].forEach((child) => {
        const span = this.bentoMenu.querySelector(`span:nth-child(${child})`);
        if (span) span.style.cssText = '';
      });
    });
    return tl;
  }

  closeBentoMenu() {
    if (this.bentoMenu.classList.contains('active-bento')) {
      this.closeBentoAnimation().play();
    }
  }
}
