/* eslint-disable no-undef */
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import barba from '@barba/core';
import VanillaTilt from 'vanilla-tilt';

import { ANIMATION_CONFIG } from '../config/constants';

export const PageTransition = {
  menuInstance: null,
  curtain: null,

  setMenuInstance(instance) {
    this.menuInstance = instance;
  },

  createCurtain() {
    const curtain = document.createElement('div');
    curtain.className = 'page-transition-curtain';
    document.body.appendChild(curtain);
    return curtain;
  },

  setupScrollToTop() {
    barba.hooks.afterLeave(() => {
      if (window.smoothScrollInstance) {
        window.smoothScrollInstance.reset();
      }
      window.scrollTo(0, 0);
    });
  },

  handleMenuAnimation() {
    const header = document.querySelector('.header');
    if (!this.menuInstance || !header.classList.contains('active-menu')) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      const tl = gsap.timeline({ onComplete: resolve });
      tl.add(this.menuInstance.closeMenu());
    });
  },

  createScrollTrigger() {
    ScrollTrigger.create({
      start: 'top -40',
      end: 99999,
      toggleActions: 'play none none reverse',
      toggleClass: {
        className: 'header__inner--scrolled',
        targets: '.header__inner'
      }
    });
  },

  initParallaxEffects() {
    gsap.utils.toArray('.with-parallax').forEach((section) => {
      const image = section.querySelector('img');
      ScrollTrigger.create({
        trigger: section,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        animation: gsap.to(image, {
          yPercent: 25,
          ease: 'none'
        })
      });
    });
  },

  initTiltIfDesktop() {
    const cardTilt = document.querySelectorAll('.card-tilt');
    VanillaTilt.init(cardTilt, {
      max: 10,
      speed: 3000,
      transition: true,
      easing: 'cubic-bezier(.03,.98,.52,.99)',
      perspective: 1500,
      gyroscope: true,
      glare: true,
      'max-glare': 0.35
    });
  },

  async animateLeave({ current }) {
    const timeline = gsap.timeline();
    timeline
      .set(this.curtain, { y: '100%' })
      .to(this.curtain, {
        y: '0%',
        duration: ANIMATION_CONFIG.TRANSITION.DURATION,
        ease: 'power2.inOut'
      })
      .to(
        current.container,
        {
          opacity: 0,
          duration: ANIMATION_CONFIG.TRANSITION.DURATION / 2
        },
        `-=${ANIMATION_CONFIG.TRANSITION.DURATION / 2}`
      );
    return timeline;
  },

  async animateEnter({ next }) {
    const timeline = gsap.timeline();
    gsap.set(next.container, { opacity: 0 });
    timeline
      .to(next.container, {
        opacity: 1,
        duration: ANIMATION_CONFIG.TRANSITION.DURATION / 2
      })
      .to(this.curtain, {
        y: '-100%',
        duration: ANIMATION_CONFIG.TRANSITION.DURATION,
        ease: 'power2.inOut'
      })
      .set(this.curtain, { y: '100%' });
    return timeline;
  },

  initHooks() {
    barba.hooks.after(() => {
      ScrollTrigger.refresh();
      if (window.smoothScrollInstance) {
        window.smoothScrollInstance.reset();
      }
    });
    barba.hooks.before(() => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    });
    barba.hooks.afterEnter(() => {
      this.initTiltIfDesktop();
      this.initParallaxEffects();
      this.createScrollTrigger();
    });
  },

  async init() {
    gsap.registerPlugin(ScrollTrigger);
    this.curtain = this.createCurtain();
    this.setupScrollToTop();
    barba.init({
      transitions: [
        {
          name: 'curtain-transition',
          leave: async (data) => {
            await this.handleMenuAnimation();
            return this.animateLeave(data);
          },
          enter: async (data) => {
            return this.animateEnter(data);
          }
        }
      ]
    });
    this.initTiltIfDesktop();
    this.initParallaxEffects();
    this.initHooks();
  }
};
